import React, { useEffect, useMemo } from 'react';
import s from './InitialPage.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import firstBanner from '../../assets/images/initial-banner-1.png';
import secondBanner from '../../assets/images/initial-banner-2.png';
import thirdBanner from '../../assets/images/initial-banner-3.png';
import forthBanner from '../../assets/images/initial-4.png';
import firstBg from '../../assets/images/season-end1.png';
import loaderImage from '../../assets/images/loader.png';
import { ReactComponent as BoostIcon } from '../../assets/icons/boost.svg';
import comboImage from '../../assets/images/combo.png';
import playAgoImage from '../../assets/images/play-ago-banner.png';
import { ReactComponent as XIcon } from '../../assets/icons/x.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discord.svg';
import { ReactComponent as TelegramIcon } from '../../assets/icons/telegram.svg';
import { ROUTES } from '../../constants';


function InitialPage() {
  window.Telegram.WebApp.BackButton.hide();
  const {initialLoading} = useSelector((state) => state.user);
  const {data: leaderboardData} = useSelector((state) => state.leaderboard);
  const navigate = useNavigate();
  const banners = [
    {bg: firstBanner, img: {type: 'img', url: playAgoImage}, text: 'Compete to Earn the most $AGO across all our Games!'},
    {bg: secondBanner, img: {type: 'icon', Icon: BoostIcon}, text: 'Boost your taps, multiply your AGO!'},
    {bg: thirdBanner, img: {type: 'img', url: comboImage}, text: 'Solve Combo every week and claim 100K $AGO'},
  ];

  const data = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * banners.length);
    return banners[randomIndex];
  }, []);

  useEffect(() => {
    if (!initialLoading && leaderboardData) {
      navigate(`${ROUTES.initial}${ROUTES.main}`);
    }
  }, [initialLoading, leaderboardData]);

  // if(data.bg === 4){
  //   return <div className={s.pageContainer} style={{justifyContent: 'flex-start'}}>
  //     <img src={forthBanner} alt="background" className={s.fourthBg} />
  //     <div className={s.titleContainer}>
  //       <p className={s.title}>
  //         Hexacore Gaming Universe
  //       </p>
  //       <img src={loaderImage} alt="loader" className={s.loader} />
  //     </div>
  //   </div>
  // }

  return (
    <div className={s.pageContainer}>
      <img src={firstBg} alt="background" className={s.bgImg} />
      {/*<div className={s.titleContainer}>*/}
      {/*  <p className={s.title}>*/}
      {/*  Hexacore Gaming Universe*/}
      {/*  </p>*/}
      {/*  <img src={loaderImage} alt="loader" className={s.loader} />*/}
      {/*</div>*/}
      {/*<div className={s.banner}>*/}
      {/*  <img src={data.bg} alt="banner" />*/}
      {/*  <div className={s.info}>*/}
      {/*    <div className={`${s.icon} ${data.img.type === 'img'?s.imgContainer:s.iconContainer}`}>*/}
      {/*      {data.img.type === 'img' ? <img src={data.img.url} alt="combo" /> : <data.img.Icon />}*/}
      {/*    </div>*/}
      {/*    <p className={s.description}>*/}
      {/*      {data.text}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className={s.infoContainer}>
          <img src={loaderImage} alt="loader" className={s.loader} />
        <p className={s.text}>Follow official channels</p>
        <div className={s.linksContainer}>
          <Link className={s.link} to={'https://twitter.com/Hexacore_UGC'} target="_blank">
            <XIcon width={24} height={24} />
          </Link>
          <Link className={s.link} to={'https://t.me/hexacore_ugc'} target="_blank">
            <TelegramIcon width={29} height={29} />
          </Link>
          <Link className={s.link} to={'https://discord.gg/2Z8XurKufH'} target="_blank">
            <DiscordIcon width={30} height={30} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default InitialPage;
